import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledFooter = styled.footer`
  margin: 0 auto 0 auto;
  padding: 2rem;
  color: #ffffff;
  font-size:32px;
  z-index:4;
  position:relative;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#ff8181, #ffa430);
  .social-icons{
  	align-self: center;
	display: inline-flex;
	align-items: center;
    display:flex;
     svg{
     float:right;
      margin:0 32px;
      cursor:pointer;
    }
    svg:hover{
      color:#4A5669;'
    }
  }
  
`

class Footer extends Component {
  render() {
    const { children } = this.props
    return <StyledFooter>{children}</StyledFooter>
  }
}

export default Footer

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}
