import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import { Lightbox } from "react-modal-image";
import styled from "@emotion/styled";
import Wrapper from "../components/Wrapper";

const PostWrapper = styled(Wrapper)`
  max-width: 800px;
  margin-top: 64px;
  margin-bottom: 64px;
  h3 {
    @media (max-width: 767px) {
      font-size: 34px !important;
    }
    @media (min-width: 768px) {
      font-size: 34px !important;
    }
  }
  p {
    line-height: 40px !important;
  }
`


class Pagination extends Component {
    constructor() {
        super()
        this.state = {
            open:false,
            lightboxURL:""
        }
    }
    openLightbox = (imagepath) => {
        this.setState({open:true,lightboxURL:imagepath});
    };

    closeLightbox = () => {
        this.setState({open:false});
    };

    render() {
        const slides = this.props.input.items.map(edge => <div> <img onClick={e => this.openLightbox(edge.image.url)} src={edge.image.url}  alt={edge.image.caption}/> {edge.caption && <p className="bodyText caption swiper-cap">{edge.caption} </p>}</div>);

        const params = {
            slidesPerView:1,
            spaceBetween:150,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        }

        return (
            <React.Fragment>
              {(this.props.input.primary.title2 !== undefined && this.props.input.primary.title2 !== null) &&
              <PostWrapper>
                <h3 dangerouslySetInnerHTML={{__html:this.props.input.primary.title2}}/>
              </PostWrapper>
              }
                <Swiper {...params}>
                    {slides}
                </Swiper>
                { this.state.open &&
                        <Lightbox
                            medium={this.state.lightboxURL}
                            large={this.state.lightboxURL}
                            onClose={this.closeLightbox}
                        />
                }

            </React.Fragment>
        )
    }
};
export default Pagination;
