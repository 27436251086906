import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import '../overwrites.css'
import { keyframes } from 'styled-components'
import Wrapper from './Wrapper'
import Menu from './Menu'

const StyledHeader = styled.nav`
  position: relative;
  background-image: ${props => `url(${props.bg})`};
  background-position: right top;
  background-size: 75%;
  background-repeat: no-repeat;
  padding-top: 54%;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    background-size: 100%;
    padding-top: 72%;
  }

  a {
    color: ${props => (props.invert ? props.theme.colors.greyLight : props.theme.colors.greyDark)};
    font-weight: 400;
    font-style: normal;
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  h1 {
    position: absolute;
    @media (max-width: 767px) {
      top: 105%;
      font-size: 48px;
      left: 0;
      margin: 0 1.5rem;
    }
    @media (min-width: 768px) {
      transform: translate(-50%, -50%);
      top: 45%;
      left: 30%;
      margin-bottom: 0;
      font-size: 48px;
    }
    @media (min-width: 992px) {
      font-size: 3.157rem;
    }
  }
`

class Header extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { invert, title, bg, postsFromPost } = this.props
    return (
      <StyledHeader invert={invert} bg={bg}>
        <Menu postsFromPost={postsFromPost} />
        <h1>{title}</h1>
      </StyledHeader>
    )
  }
}

export default Header

Header.propTypes = {
  invert: PropTypes.bool,
}

Header.defaultProps = {
  invert: false,
}
