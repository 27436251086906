import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Wrapper from '../components/Wrapper'
import {Link, RichText, Date} from 'prismic-reactjs';
import InstagramEmbed from 'react-instagram-embed';

const Content = styled.div`
  max-width: ${props => props.theme.maxWidthText};
`

const PostWrapper = styled(Wrapper)`
  max-width: 800px;
  margin-top: 64px;
  margin-bottom: 64px;
  h3 {
    @media (max-width: 767px) {
      font-size: 34px !important;
    }
    @media (min-width: 768px) {
      font-size: 34px !important;
    }
  }
  p {
    line-height: 40px !important;
  }
`

const ResponsiveIframe = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
    }
`
const InstaFrame = styled.div`
    position: relative;
    width:100%!important;
    display:block;
    *{
   width:100%!important;
   max-width:100%!important;
}
`

const Caption = styled.div`
  margin-left: 32px;
  margin-top: 16px;
  color: #b0b0b0;
`

const ContentBlock = ({ input }) => (
    <PostWrapper>

        {(input.primary.section_text[0].oembed.provider_name == "Instagram") &&
            <InstaFrame>
                <InstagramEmbed
                    url={input.primary.section_text[0].oembed.embed_url}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                />
            </InstaFrame>
        }
        {(input.primary.section_text[0].oembed.provider_name != "Instagram") &&
        <ResponsiveIframe>
            {RichText.render(input.primary.section_text)}
        </ResponsiveIframe>
        }

        <Caption>{input.primary.section_title.text}</Caption>
    </PostWrapper>
)

export default ContentBlock

ContentBlock.propTypes = {
    input: PropTypes.object.isRequired,
}
