import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Item = styled.li`
  margin-bottom: 1.45rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  font-size: 2.369rem;
  color: #000000;
  font-style: normal;

  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   font-size: 1.777rem;
  // }
`

const StyledImage = styled.img`
  position: absolute;
  max-width: 40%;
  right: 0;
  top: 0;
`

export default class MenuListItem extends Component {
  // 2. bind it with fat arrows.

  render() {
    const { node } = this.props
    return (
      <Item>
        <StyledLink to={node.uid}>{node.data.title.text}</StyledLink>
      </Item>
    )
  }
}

MenuListItem.propTypes = {
  node: PropTypes.object.isRequired,
}
