import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import MenuListItem from './MenuListItem'

const List = styled.ul`
  margin-top: 4rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
  position: relative;
  text-align: center;
  color: #ffffff;
`

const StyledLink = styled(Link)`
  font-size: 2.369rem;
  color: #000000;
  font-style: normal;
  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   font-size: 1.777rem;
  // }
`
const Item = styled.li`
  margin-bottom: 1.45rem;
`
export default class MenuListing extends Component {
  render() {
    const { posts, postsFromPost } = this.props
    console.log(posts)
    console.log(postsFromPost)
    return (
      <List>
        <Item>
          <StyledLink to="/">Home</StyledLink>
        </Item>
        {posts !== undefined && (
          <>
            {posts.map(post => (
              <React.Fragment key={post.pages.document[0].uid}>
                <MenuListItem key={post.pages.document[0].uid} node={post.pages.document[0]} />
              </React.Fragment>
            ))}
          </>
        )}
        {postsFromPost !== undefined && (
          <>
            {postsFromPost.edges.map(post => (
              <React.Fragment key={post.node.uid}>
                <MenuListItem key={post.node.uid} node={post.node} />
              </React.Fragment>
            ))}
          </>
        )}
      </List>
    )
  }
}

MenuListing.propTypes = {}
