import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Tween, Timeline } from 'react-gsap'

const Item = styled.li`
  margin-bottom: 1.45rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  font-size: 2.669rem;
  color:#ffffff;
  font-style: normal;
  position:relative;
  &:hover{
  text-decoration:none!important;
  }
  &:before{
   content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  visibility: hidden;
  -webkit-transform:scaleX(0);
  transform: scaleX(0);
  transform-origin: 0% 100%;
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
   -webkit-transform: scaleX(1);
   transform: scaleX(1);
  }
  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   font-size: 1.777rem;
  // }
`

const StyledImage = styled.img`
  position: absolute;
  max-width: 50%;
  right: 0;
  top: 0;
  @media (max-width: 767px) {
    display: none;
  }
`

const TweenComponent = () => (
  <Tween from={{ x: '100px', rotation: -360 }}>
    <div>This element gets tweened</div>
  </Tween>
)

export default class ListItem extends Component {

  render() {
    const { node } = this.props

    return (
      <Item>
        <StyledLink to={node.uid}  >
          {node.data.title.text}
        </StyledLink>
      </Item>
    )
  }
}

ListItem.propTypes = {
  node: PropTypes.object.isRequired,
}
