import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Wrapper from '../components/Wrapper'
import {Link, RichText, Date} from 'prismic-reactjs';

const Content = styled.div`
  max-width: ${props => props.theme.maxWidthText};
`

const PostWrapper = styled(Wrapper)`
  max-width: 800px;
  margin-top: 64px;
  margin-bottom: 64px;
  h3 {
    @media (max-width: 767px) {
      font-size: 34px !important;
    }
    @media (min-width: 768px) {
      font-size: 34px !important;
    }
  }
  p {
    line-height: 40px !important;
  }
`

const BodyText = ({ input }) => (
  <PostWrapper>
    <React.Fragment>
        <h3 dangerouslySetInnerHTML={{__html:input.primary.title1.text}}/>
        <div dangerouslySetInnerHTML={{__html:input.primary.content.html}}/>
    </React.Fragment>
  </PostWrapper>
)

export default BodyText

BodyText.propTypes = {
  input: PropTypes.object.isRequired,
}
