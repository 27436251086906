import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import ModalImage from 'react-modal-image'

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width:${props => `${props.maxWidth}%`};
    };

   &.left{
  	margin: 64px auto 64px 0;
  }
  &.right{
    margin: 64px 0 64px auto;
  }
  &.center{
    margin: 64px auto 64px auto;
    max-width: 720px;
  }
  @media(max-width:767px){
   margin: 0 auto;
    padding: 0 1.5rem;
    max-width: 720px;
    
  }
`

const Caption = styled.div`
  margin-left: 32px;
  margin-top: 16px;
  color: #b0b0b0;
`

const Image = ({ input }) => (
  <Content className={input.primary.position} maxWidth={input.primary.max_width_percentage}>
    <ModalImage
      small={input.primary.image.url}
      large={input.primary.image.url}
      alt={input.primary.caption}
    />

    <Caption>{input.primary.caption}</Caption>
  </Content>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
