import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListItem from './ListItem'
import Image from '../../slices/Image'

const List = styled.ul`
  margin-top: 4rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
  position: relative;
`

export default class Listing extends Component {
    constructor(props) {
        super(props)

        // 1. bind your functions in the constructor.
        this.mouseOver = this.mouseOver.bind(this)
        this.mouseOut = this.mouseOut.bind(this)
        this.state = {
            hover: false,
            position: 0,
        }
    }

    // 2. bind it with fat arrows.
    mouseOver = () => {
        this.setState({ hover: true })
    }

    mouseOut() {
        this.setState({ hover: false })
    }

    transitiontoNext(position,posts){
        if(position == posts.length-1) {
            setTimeout(
                function () {
                    this.setState({position : 0});
                }
                    .bind(this),
                3000
            );
        }else{
            let newPosition = this.state.position+1;
            setTimeout(
                function () {
                    this.setState({position : newPosition});
                }
                    .bind(this),
                3000
            );
        }
    }

    render() {
    const { posts } = this.props;
    // console.log(this.state.position);
    this.transitiontoNext(this.state.position,posts);

    return (
      <List>
        {posts.map((post,i) => (
          <React.Fragment key={post.pages.document[0].uid}>
              { (i == this.state.position) &&
                  <ListItem  key={post.pages.document[0].uid} node={post.pages.document[0]} autoActive={true} />
              }
              { (!(i == this.state.position)) &&
                  <ListItem key={post.pages.document[0].uid} node={post.pages.document[0]} autoActive={false} />
              }
          </React.Fragment>
        ))}
      </List>
    )
  }
}

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}
