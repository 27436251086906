import styled from '@emotion/styled'
import React, { Component } from 'react'
import MenuListing from './Listing/MenuListing'
import { Location } from '@reach/router';
import PropTypes from 'prop-types';


const Hamburger = styled.nav`
  .hamburger {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
    z-index:5;
    @media (min-width: 768px) {
      left: 2.666666666666667rem;
      top: 2.666666666666667rem;
    }
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 7px);
    transform: rotate(-45deg) translate(-8px, 7px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
`

const MenuBG = styled.menu`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  background-color: #fbaf5d;
  transition: all 0.3s ease-in;
  opacity: 0;
  visibility: hidden;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in;
  }
  .close {
    display: ${props => (props.active ? 'block' : 'none')};
    top: 2.666666666666667rem;
    left: 2.666666666666667rem;
    z-index: 11;
  }
`

const SiteName = styled.span`
  font-size: 32px;
  color: #ffffff;
  position: absolute;
  top: 3.1rem;
   z-index:2;
  right: 2.666666666666667rem;
  pre {
    font-size: 24px;
    overflow: hidden;
  }
  @media (max-width: 767px) {
    right: 1.5rem;
    top: 2rem;
  }
`
const labelCaption ={
  position: 'absolute',
  right: '0',
  bottom: '-72px',
  fontSize:'4vw'
};

 class Menu extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggleActive = () => {
    this.setState(prevState => ({
      active: !this.state.active,
    }))
  }

  render() {
    const { posts1, postsFromPost, siteTitle, location } = this.props;
    console.log("location",location);
    var onHomepage = " homepage";
      if (location.pathname.length > 1) {
        onHomepage = "";
      }

    return (
      <React.Fragment>
        <SiteName dangerouslySetInnerHTML={{ __html: siteTitle }}></SiteName>
        <Hamburger>
          <div className="hamburger" onClick="myFunction(this)" onClick={this.toggleActive}>
            <div className={this.state.active ? 'change' : ''}>
              <div className={"bar1" + onHomepage}></div>
              <div className={"bar2" + onHomepage}></div>
              <div className={"bar3" + onHomepage}></div>
            </div>
          </div>
        </Hamburger>
        <MenuBG active={this.state.active} className={this.state.active ? 'active' : ''}>
          <Hamburger>
            <div className="hamburger" onClick="myFunction(this)" onClick={this.toggleActive}>
              <div className={this.state.active ? 'change' : ''}>
                <div className={"bar1" + onHomepage}></div>
                <div className={"bar2"+ onHomepage}></div>
                <div className={"bar3"+ onHomepage}></div>
              </div>
            </div>
          </Hamburger>
          <MenuListing posts={posts1} postsFromPost={postsFromPost} />
          <div className="quote-anim"><span style={{position:'relative'}}>If it doesn't sell, it isn't creative.<span style={labelCaption}> - David Oglivy</span></span></div>
          <div className="quote-anim1"><span style={{position:'relative'}}>Simplicity, wit, and good typograpgy.<span style={labelCaption}> - Michael Bierut</span></span></div>

        </MenuBG>
      </React.Fragment>
    )
  }
}

export default props => (
  <Location>
    {locationProps => <Menu {...locationProps} {...props} />}
  </Location>
);