/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'
import '../styles/index.css'
import { FaEnvelope, FaInstagram, FaLinkedin, FaReadme, FaChevronCircleUp } from 'react-icons/fa'
import Footer from './Footer'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'
import 'typeface-lora'
import 'typeface-source-sans-pro'
import ScrollToTop from 'react-scroll-up'
import { Modal, Button, Form} from 'react-bootstrap'
import { Link } from 'gatsby'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }

  @import url('https://fonts.googleapis.com/css?family=Tajawal&display=swap');

  * {
    font-family: 'Tajawal', sans-serif !important;
    font-weight: 300 !important;
  }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .social-icons {
    right: 0;
  }
  .ctaoner {
    text-align: center;
    margin-bottom: 32px;
    @media (min-width: 768px) {
      width: 100%;
    }
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #4a5669;
    border: 1px solid transparent;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 0.25rem;
    font-style: normal;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    padding: 0.8rem 2rem;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .btn:hover {
    text-decoration: none;
    color: #4a5669;
    background-color: #ffffff;
    border-color: #000000;
  }
  a{color:#ffffff;
  }
`
const scroller = {
  zIndex:'20',
  position: 'fixed',
  bottom: 50,
  right: 30,
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s'
}
class PureLayout extends Component {
  constructor(...args) {
    super(...args)

    this.state = { modalShow: false }
  }

  render() {
    const { children, data, customSEO } = this.props
    const modalClose = () => this.setState({ modalShow: false })
    console.log("here",data);
    return (
      <ThemeProvider theme={theme}>
        <>
          <MyVerticallyCenteredModal show={this.state.modalShow} onHide={modalClose} />
          <ScrollToTop showUnder={160} style={scroller}>
            <span style={{ fontSize: '40px', zIndex: '20', color: '#4a5669' }}>
              <FaChevronCircleUp />
            </span>
          </ScrollToTop>
          <Global styles={globalStyle} />
          <SkipNavLink />
          {!customSEO && <SEO />}
          {children}
          <Footer>
            {/* <p dangerouslySetInnerHTML={{ __html: data.prismicHomepage.data.footer.html }} /> */}
            <div className="ctaoner">
              <p>If you liked what you saw I'm always free to have a&nbsp;chat</p>
              <a className="btn" href="mailto:gusshoustonsc@outlook.com?subject=Gustave!%2C%20I%20want%20to%20get%20in%20touch&amp;body=Hi%20Gustave%2C%20I%20would%20like%20to%20get%20in%20touch.">
                Get in Touch
              </a>
            </div>
            <div className="social-icons">
              <a target="_blank" href={"mailto:" + data.prismicHomepage.data.email_link}>
                <FaEnvelope />
              </a>
              <a target="_blank" href={data.prismicHomepage.data.instagram_link}>
                <FaInstagram />
              </a>
              <a target="_blank" href={data.prismicHomepage.data.linkedin_link}>
                <FaLinkedin />
              </a>
              <a target="_blank" href={data.prismicHomepage.data.resume_link}>
                <FaReadme />
              </a>
            </div>
          </Footer>
        </>
      </ThemeProvider>
    )
  }
}

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            prismicHomepage {
              data {
                footer {
                  html
                }
                email_link
                instagram_link
                linkedin_link
                resume_link
              }
            }
          }
        `}
        render={data => (
          <PureLayout {...this.props} data={data}>
            {this.props.children}
          </PureLayout>
        )}
      />
    )
  }
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  data: PropTypes.object.isRequired,
  customSEO: PropTypes.bool,
}

PureLayout.defaultProps = {
  customSEO: false,
}

class MyVerticallyCenteredModal extends React.Component {
  render() {
    return (
      <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Send me a message!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>

            <Form.Group controlId="Name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name" />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message/Question</Form.Label>
              <Form.Control as="textarea" rows="3" placeholder="Your message for me" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}
